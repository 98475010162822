.sudoku-square {
  background: #fff;
  /*border: 1px solid #999;*/
  /*float: left;*/
  font-size: 24px;
  font-weight: bold;
  /*line-height: 34px;*/
  height: 34px;
  /*margin-right: -1px;*/
  /*margin-top: -1px;*/
  padding: 0;
  text-align: center;
  width: 34px;
}

.sudoku-square-right {
  border-right-width: 3px;
  border-right: solid;
}

.sudoku-square-bottom {
  border-bottom-width: 3px;
  border-bottom: solid;
}

.sudoku-square:focus {
  outline: none;
}

.sudoku-given-square {
  color: #556cd6;
}

.sudoku-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  background-color: #2196F3;
  padding: 1px;
}

.sudoku-grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
  padding: 1px;
  font-size: 30px;
  text-align: center;
}

.sudoku-square-grid-container {
  display: grid;
  grid-template-areas: 'tl tr'
    'mid mid'
    'bl br';
}

.sudoku-square-grid-item {
  font-size: 9px;
  text-align: center;
}

.sudoku-square-grid-item-tl {
  grid-area: tl
}

.sudoku-square-grid-item-tr {
  grid-area: tr
}

.sudoku-square-grid-item-mid {
  grid-area: mid
}

.sudoku-square-grid-item-bl {
  grid-area: bl
}

.sudoku-square-grid-item-br {
  grid-area: br
}
